@mixin glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin glyphicon-menu-right {
  &:before { content: "\e258"; }
}

@mixin glyphicon-menu-down { &:before { content: "\e259"; } }
@mixin glyphicon-menu-up { &:before { content: "\e260"; } }

@mixin reset_list {
  list-style: none; margin: 0; padding: 0;

  li {
    margin: 0;
    padding: 0;
    background: none;
  }

}

.clear {
  clear: both;
}

@mixin input-placeholder($color) {
  &.placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

@mixin background-size($width, $height) {
  -webkit-background-size: $width $height;
  -o-background-size: $width $height;
  -moz-background-size: $width $height;
  background-size: $width $height;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin flex-basis($values) {
  -webkit-box-flex-basis: $values;
  -moz-box-flex-basis:  $values;
  -webkit-flex-basis:  $values;
  -ms-flex-basis:  $values;
  flex-basis:  $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin transition-effekt {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@mixin transition-one-effekt($effect) {
  -webkit-transition: $effect 0.3s;
  transition: $effect 0.3s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}