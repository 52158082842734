.btn-blue,
.btn-large-blue,
.btn-orange,
.btn-large-orange {
  @extend .btn;
  @include button-size(15px, 30px, 20px, 30px, $border-radius);

  @include button-variant(#fff, $ci-primary, $ci-primary);
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
  background-color: transparent;
  text-transform: uppercase;
  color:$ci-primary;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0;
    background: $ci-primary;
    text-decoration: none;
    color:#fff;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(none);
  }

}

.btn-orange,
.btn-blue {
  @include button-size(5px, 20px, 15px, 30px, 0px);
}
.btn-blue,
.btn-large-blue {
  border: 2px solid $ci-primary;
}
.btn-orange,
.btn-large-orange {
  @include button-variant(transparent, $ci-secondary, $ci-secondary);
  border: 2px solid $white;
  color: $white;
  &:hover,
  &:focus,
  &.focus {
    border-color: $ci-primary;
    color: $white;
    background: $ci-primary;
    text-decoration: none;
  }
}
