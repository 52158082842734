$red: #E10A19;

$sparda-black: #404040;
$white: #FFF;

$border-color: $ci-primary;

$mobil-nav-bg: $ci-primary;
$mobil-nav-color: #fff;

$breadcrumb-bg: $ci-primary;

$dark-blue: $ci-primary;

$base-font-color: $sparda-black;
$text-color:           $base-font-color;
$text-headline-color: inerhit;

$link-color:                              $ci-link-color;
$link-decoration:                         none !default;
$link-hover-color:                        $ci-secondary;
$link-hover-decoration:                   underline !default;

$list-icon-color: $ci-secondary;


$font-family-nav:  'Roboto Condensed', sans-serif !default;
$font-family-sans-serif:  'Roboto', sans-serif !default;
$font-family-serif: 'Merriweather', serif;
$font-family-postit: 'Kalam';

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$base-font-size: 17px;

$resources-path: "../../../../Public";

$color-red-error: #f00000;
$zindex-select-dropdown:          1000 !default;
$color-grey-arrow: $sparda-black;

$sm-space: 15px;
$md-space: 35px;
$lg-space: 50px;

$container-max-widths: ();
// stylelint-disable-next-line scss/dollar-variable-default

$container-max-widths: map-merge(
                (
                        sm: 540px,
                        md: 750px,
                        lg: 970px,
                        xl: 1170px
                ),
                $container-max-widths
);

$logo_url: '../../../../Public/Img/meine-ferienimmobilie-logo.svg';

//* Bootstrap 3 -> 4
$brand-primary:         $ci-primary !default;
$brand-secondary:       $ci-secondary !default;
$brand-tertiary:       $ci-tertiary !default;
$pagination-disabled-border:           #ddd !default;
//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

$border-radius: 7px;

// Mega menu
$sf-mega-background: $ci-tertiary;
$sf-mega-color: $base-font-color;
$sf-mega-spacer-border-color: $ci-secondary;

// Footer
$footer-top-background: $ci-secondary;
$footer-background: $ci-tertiary;
$footer-color: $base-font-color;

// Header Teaser
$header-teaser-box-headline-color: $ci-secondary;
$header-teaser-box-subline-color: $ci-primary;

// Teaser Box
$teaser-box-color: $base-font-color;
$teaser-box-background: $ci-tertiary;
$teaser-box-headline-color: $ci-secondary;
$teaser-box-btn-color: #fff;
$teaser-box-btn-background: $ci-secondary;

// Reference object
$reference-object-background: $ci-tertiary;

// Location box
$location-box-background: $ci-tertiary;
$location-box-link-color: $ci-secondary;
