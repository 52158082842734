@charset "UTF-8";
@import "../../functions/hls";

$ci-primary: #1E3252;
$ci-secondary: #BEAF8C;
$ci-secondary-light: #f3efe9;
$ci-tertiary: #e1e8f4;
$ci-link-color: #4D6487;
$ci-table-background-color: #ece8dd;

// include partials (please add partials only in the include file)
@import "variables";
@import "../../partials/include";
