@charset "UTF-8";
@import "../../functions/hls";

:root {
  --ci-primary: hsl(217, 46%, 22%);
  --ci-primary-h: 217;
  --ci-primary-s: 46%;
  --ci-primary-l: 22%;

  --ci-secondary: hsl(42, 28%, 65%);
  --ci-secondary-h: 42;
  --ci-secondary-s: 28%;
  --ci-secondary-l: 65%;

  --ci-tertiary: hsl(36, 29%, 93%);
  --ci-tertiary-h: 36;
  --ci-tertiary-s: 29%;
  --ci-tertiary-l: 93%;

  --ci-link-color: hsl(216, 27%, 42%);
  --ci-link-color-h: 216;
  --ci-link-color-s: 27%;
  --ci-link-color-l: 42%;

  --ci-table-background-color: hsl(44, 28%, 90%);
  --ci-table-background-color-h: 44;
  --ci-table-background-color-s: 28%;
  --ci-table-background-color-l: 90%;
}

$ci-primary: #{"var(--ci-primary)"};
$ci-secondary: #{"var(--ci-secondary)"};
$ci-secondary-light: #{"var(--ci-secondary-light)"};
$ci-tertiary: #{"var(--ci-tertiary)"};
$ci-link-color: #{"var(--ci-link-color)"};
$ci-table-background-color: #{"var(--ci-table-background-color)"};

// include partials (please add partials only in the include file)
@import "variables";
@import "../../partials/_include";


