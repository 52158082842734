footer {
  background: $footer-background;
  color: $footer-color;
  font-size: 15px;
  .top-link {
    background: $footer-top-background;

    a {
      color: $white;
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;

      &:hover {
        text-decoration: none;
      }
      i {
        font-size: 75%;
        margin-right: 8px;
      }
    }
  }
  .footer-content {
    padding: 40px 0;
  }

  a {
    color: unset;
    word-break: break-word;
    hyphens: auto;
    font-weight: 500;
  }
  .email::before {
    content: "\f0e0";
    font-family: "Font Awesome 6 Free";
    margin-right: 5px;
  }
  .phone::before {
    content: "\f095";
    font-family: "Font Awesome 6 Free";
    margin-right: 5px;
  }

  .footer-nav {
    display: flex;

    .container {
      padding: 0;
    }

    .meta-nav {
      padding: 0 15px;
      text-align: center;
      line-height: 32px;

      a {
        display: block;
      }

      ul {
        padding: 15px 0;
        border-top: 1px solid $ci-secondary;
      }
    }

    .meta-advice {
      background: $footer-background;
      padding: 15px;
      color: $footer-color;
      line-height: 32px;
      vertical-align: middle;
      text-align: right;
      .robeo-group-logo {
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
        @include media-breakpoint-up(sm) {
          svg {
            margin-top: -36px;
          }
        }
      }
    }
  }

  ul {
    @include reset_list;

    li {
      padding: 5px 0;
      line-height: 20px;
    }

    a {
      display: block;
      color: $footer-color;
    }
  }
  .col-xl-2 {
    @include media-breakpoint-down(lg) {
      text-align: center;

      br {
        display: none;
      }
      p {
        a {
          display: inline-block;
          padding: 0 8px;
          &:after {
            content:"\00b7";
            padding-left: 8px;
          }

          &:last-child {
            &:after {
              content:" ";
            }
          }
        }
      }
    }
  }
}



@include media-breakpoint-down(sm) {
  footer {
    .meta-nav {
      ul {
        li {
          &:nth-child(even) {
            display: none;
          }
        }
      }
    }
  }


}

@include media-breakpoint-up(md) {
  footer {

    ul {
      li {
        display: inline-block;
        padding: 0px 20px 0 0;
      }
    }
    .footer-nav {
      background: $footer-background;

      .container {
        padding: 0 15px;
      }

      .meta-nav {
        padding: 20px 0;
        float: left;

        ul {
          padding: 0;
          border: 0;
        }

      }
      .meta-advice {
        padding: 20px 0;
        float: right;
      }
    }
  }
}