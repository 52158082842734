#nav-waypoint {
  position: absolute;
  top: 70px;
}

#page1 #nav-waypoint {
  top: 90px;
}

.header-wrap {
  position: fixed;
  width: 100%;
  z-index: 2000;
  top: 0;
}

header.header {
  border-bottom: 4px solid $ci-secondary;
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }


  background: #fff;
  @include transition-effekt;

  .header-container {
    @extend .container;
  }

}

.main-nav-active {
  header.header {
    background: $ci-primary;
  }
}

.header-background {
  header {
    background: #fff;
    @include transition-effekt;
  }
}

.mfi-logo {
  padding: 15px 0;
  margin: 0 65px;
  z-index: 10;
  position: relative;
  a {
    display: block;
    height: 45px;
    background: transparent url('#{$logo_url}') no-repeat center top;

    @include background-size(auto, 45px);
  }
}

.header-nav {
  z-index: 5;
}

.quick-contact-nav {
  display: none;
  @extend .roboto-medium;
  position: absolute;
  right: 0;
}

.header-phone-no {
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;
  .header-phone-corner {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    border-width: 0 35px 35px 0;
    border-color: transparent $ci-primary transparent transparent;
    float: left;
  }
  .phone-number {
    width: 100%;
    min-width: 218px;
    display: inline-block;
    background: $ci-secondary;
    color: $white;
    font-size: 15px;
    line-height: 35px;
    float: right;
    padding: 5px 15px 5px 25px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      background: $white;
      a, i {
        color: $ci-secondary;
      }
    }
    span.dial-number{
      width: 169px;
      display: inline-block;
    }
  }
  .dial-number {
    a {
      color: #fff;
      padding-left: 8px;

      &:before {
        left: -8px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.newsletter-box {
  .newsletter-box-link {
    background: $ci-primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    line-height: 35px;
    padding: 5px 15px 5px 25px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    a {
      color: #fff;
      display: block;
    }
     &:hover {
      background: $white;
      a {
        color: $ci-primary;
        text-decoration: none;
      }
    }
  }
}


.top-padding {
  padding-top: 30px;
}
.bottom-padding {
  padding-bottom: 30px;
}

@include media-breakpoint-up(lg) {
  .mfi-logo {
    float: left;
    padding: 17px 0 5px 0;
    @include transition-effekt;
    margin: 0;
    width: 325px;
    a {
      display: block;
      width: 330px;
      height: 65px;
      background: transparent url($logo_url) no-repeat left bottom;

      @include background-size(auto, 100%);
      @include transition-effekt;
    }
  }
  .quick-contact-nav {
    margin-top: 40px;
    .header-phone-no {
      margin-bottom: 5px;
    }
  }
  .header-nav {
    padding-top: 43px;
    position: relative;
    @include transition-effekt;
  }

  .newsletter-box {
    .newsletter-box-link {
      font-size: 15px;
    }

  }
  .shrinked-header {
    .mfi-logo {
      padding: 15px 0;
      @include transition-effekt;
      a {
        height: 33px;
        @include background-size(auto, 100%);
      }
    }
    .header-nav {
      padding-top: 0px;
      @include transition-effekt;
    }
  }
}
#page185 .quick-contact-nav {
  .header-phone-no {
    display:none;
    background-color:#c42a34;
  }
  .newsletter-box .newsletter-box-link{
    background:#c42a34;
  }
}

@include media-breakpoint-up(xl) {

}
