/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Roboto/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Roboto-Condensed/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Merriweather/merriweather-v30-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* kalam-regular - latin */
@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 400;
  src: url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$resources-path}/Fonts/Kalam/kalam-v16-latin-regular.svg#Kalam') format('svg'); /* Legacy iOS */
}

.roboto-condensed {
  font-family: 'Roboto Condensed';
  font-weight: $font-weight-bold;
}

.roboto-regular {
  font-family: 'Roboto';
  font-weight: 400;
}

.roboto-medium {
  font-family: 'Roboto';
  font-weight: $font-weight-medium;
}
.roboto-bold {
  font-family: 'Roboto';
  font-weight: $font-weight-bold;
}

body {
  font-family: $font-family-sans-serif;
  color: $base-font-color;
  font-size: 16px;
}

b, strong {
  font-weight: $font-weight-bold;
}
a {
  color: $ci-link-color;
  font-weight: 600;
}
h1.text-left,
h2.text-left,
h3.text-left,
h4.text-left,
h5.text-left,
h6.text-left,
p.text-left {
  text-align: left;
}

h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h6.text-center,
h5.text-center,
p.text-center {
  text-align: center;
}

h1.text-right,
h2.text-right,
h3.text-right,
h4.text-right,
h5.text-right,
h6.text-right,
p.text-right {
  text-align: right;
}

h1.text-justify,
h2.text-justify,
h3.text-justify,
h4.text-justify,
h5.text-justify,
h6.text-justify,
p.text-justify {
  text-align: justify;
}

h1, h2,
.h1, .h2, .teaser {
  font-family: $font-family-serif;
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;
  color: $text-headline-color;
}

h2, .h2 {
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 30px;
}

h3, .h3 {
  font-family: $font-family-serif;
  letter-spacing: 1px;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 32px;
  padding: 0;
  margin: 0;
  color: $text-headline-color;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-size: 15px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  color: $text-headline-color;
}

.font-teaser {
  font-size: 30px;
  line-height: 40px;
}

p {
  margin: 0;
  line-height: 27px;
  padding: 0 0 30px;
  &.lead {
    font-weight: $font-weight-bold;
  }
}

.font-copy {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: 1.42857;
}

i {

}

a, a:focus {
  outline: none;
}

.list-disc {
  padding: 0 0 30px 20px;
  list-style: disc;
}

.list-blue-disc {
  list-style: none;
  @include reset_list;

  padding: 0 0 30px;
  line-height: 30px;

  li {
    padding: 0 0 0 15px;
    background: transparent;
    position: relative;
    &:before {
      content: "\002022";
      color: $ci-primary;
      font-size: 1.2em;
      line-height: 1;
      position: absolute;
      left: 3px;
      top: 6px;
    }

  }
}

ol {
  margin-top: 0;
  margin-bottom: 30px;
}

.main-header {
  padding-bottom: 20px;

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
  }

  &.page-header {
    padding-top: 50px;
    padding-bottom: 80px;
    color: $text-color;
  }
  &.text-center {
    text-align: center;
  }
}

h1, h2, h3, h4 {
  &.page-header {
    padding-top: 50px;
    padding-bottom: 80px;
    margin: 0;
    color: $text-color;
  }
}

.phone-no {
  display: inline-block;
  color: $text-color;
  &:before {
    margin-right: 8px;
  }
  a {
    display: inline-block;
    color: $ci-link-color;
    &:hover {
      color: $ci-secondary;
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    font-size: $base-font-size;
  }
  h1, .h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .teaser {
    font-size: 40px;
    line-height: 25px;
  }

  h2, .h2 {
    font-size: 35px;
    line-height: 40px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 32px;
  }

  h4, .h4 {
    font-size: 21px;
    line-height: 26px;
  }

  h5, .h5 {

  }
  h6, .h6 {

  }

  p {

  }
  .font-copy {
    font-size: $base-font-size;
  }
}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

}
