.breadcrumb {
  margin-bottom: 0px;
  background-color: transparent;
  ul {
    @include reset_list;
    li {
      display: inline-block;
      padding: 5px 0;

      + li:before {
        font-family: 'Font Awesome 6 Free';
        content: '\f054';
        font-size: 75%;
        margin-right: 3px;
      }
      a {
        color: $ci-link-color;
      }
      &.current {
        a {
          color: $ci-secondary;
        }
      }

    }

  }
}
