.immo-filter {
  @include make-row(20px);
  min-height: 80px;
  padding-bottom: 20px;
  margin-top: 40px;
/*
  > div {
    @include make-col-ready(20px);
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }
  .bootstrap-select {
    width: 100%;
  }*/
}

.sorting-paging-top,
.sorting-paging-bottom {
  @include make-row(20px);
  padding-bottom: 30px;

  .sorting {
    @include make-col-ready(20px);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    ul {
      @include reset_list;

      li {
        padding-right: 40px;
        display: inline-block;
        position: relative;
        span {
          width: 0px;
          height: 0px;
          -webkit-transform:rotate(360deg);
          border-style: solid;
          border-width: 0 5px 7px 5px;
          border-color: transparent transparent $sparda-black transparent;
          display: inline-block;
          position: absolute;
          top: 8px;
          right: 15px;
        }

        &.desc {
          span {
            border-width: 7px 5px 0 5px;
            border-color: $sparda-black transparent transparent transparent;
          }
        }
        &.active {
          color: $ci-secondary;
          span {
            border-color: transparent transparent $ci-secondary transparent;
          }
          &.desc {
            span {
              border-color: $ci-secondary transparent transparent transparent;
            }
          }
        }
      }
    }
  }

  nav {
    @include media-breakpoint-up(md) {
      @include make-col-ready(20px);
      @include make-col(8);
    }
  }
  .pagination {
    margin: 0;
    float: right;
  }
}

.object-list-items {
  > ul.list-items {
    @include reset_list;
    border-top: 1px solid $border-color;
    padding: 0 0 40px;

    > li {
      border-bottom: 1px solid $border-color;
      padding: 40px 0;
    }
  }
}


.object-item {
  .object-photo {
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .object-infos {
    @include clearfix;
    padding: 0;

    h3 {
      padding-bottom: 0;
    }
    .object-data ul {
      padding-bottom: 30px;
    }
    .expose-link a {
      color: $ci-primary;
    }
  }
}

.reference-object {

  padding: 0;
  margin-bottom: 40px;
  position: relative;
  background: $reference-object-background;
  border-radius: $border-radius;

  .object-item {
    .object-photo {
      img {
        border-radius: $border-radius $border-radius 0 0;
      }
    }

    .object-label {
      position: absolute;
      background: $ci-primary;
      color: #fff;
      padding: 10px 40px;
      min-width: 200px;
      text-align: left;
      top: 40px;
      left: 0;
      z-index: 1;
    }
    .object-infos {
      padding: 30px 0px 0;

      .object-logo, h3 {
        text-align: center;
        padding-bottom: 35px;
      }
      .object-logo {
        img {
          width: 100%;
          height: auto;
          max-width: 400px;
        }
      }
      .object-data {
        p {
          padding: 0;
          line-height: inherit;
        }
        ul {
          padding: 0 0 60px;
          line-height: 30px;
          border-top: 1px solid $border-color;

          li {
            padding: 5px 0;
            border-bottom: 1px solid $border-color;
            .label {
              width: 108px;
              padding-right: 5px;
            }
            .fact {
              margin: 0 0 0 110px;
              padding: 0 0 0 5px;
            }
          }
        }
      }
      .expose-link {
        padding-bottom: 40px;

        a.btn {
          color: $teaser-box-btn-color;
          background: $teaser-box-btn-background;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-infos {
        padding: 30px 40px 0;
        .object-data {
          ul {
            li {
              .label {
                width: 175px;
                padding-right: 15px;
              }
              .fact {
                margin: 0 0 0 160px;
                padding: 0 0 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .reference-object {
    .object-item {
      .object-photo {
        img {
          border-radius: $border-radius 0 0 $border-radius;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .object-item {
    .object-photo {
      width: 700px;
      float: left;
      padding-right: 40px;
    }
    .object-infos {
      padding: 0;
    }
  }

  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-infos {
        padding: 30px 20px 0;
        .object-data {
          ul {
            li {
              .label {
                width: 140px;
                padding-right: 10px;
              }
              .fact {
                margin: 0 0 0 125px;
                padding: 0 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .reference-object {
    margin-bottom: 60px;
    .object-item {
      .object-infos {
        padding: 30px 40px 0;
        .object-data {
          ul {
            li {
              .label {
                width: 158px;
                padding-right: 15px;
              }
              .fact {
                margin: 0 0 0 142px;
                padding: 0 0 0 15px;
              }
            }
          }
        }
      }
    }
  }
}

