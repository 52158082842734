.cta-box {
  text-align: center;
  padding-bottom: 30px;
  .cta-box-content {
    width: 80%;
    margin: 0 auto;
    overflow: auto;

    p {
      padding: 0;
      color: #fff;
      height: 30px;

    }
    h3 {
      padding-bottom: 14px;
      color: #fff;
      font-size: 21px;
      line-height: 26px;
    }
    .col-phone {
      padding: 15px;
      background: $ci-secondary;
      color: #fff;
      position: relative;
      .dial-number {
        display: inline-block;

        &:before {
          height: 30px;
          width: 30px;
          display: inline-block;
          background: transparent;
          //@extend .glyphicon;
          //@extend .glyphicon-earphone;
          margin-right: 8px;
          color: #fff;
          padding-top: 8px;
          vertical-align: top;
        }
        a {
          display: inline-block;
          color: #fff;
        }
      }
    }

    .col-mail,
    .col-mail.quick-contact-trigger-box {
      padding: 15px;
      background: $ci-primary;
      position: relative;
      span {
        color: #fff;
        //@extend .glyphicon;
        //@extend .glyphicon-envelope;
        font-size: 25px;
        line-height: 30px;
      }
      a, a:hover {
        text-decoration: none;
        color: #FFF;
      }
    }

    .col-mail.quick-contact-trigger-box {
      cursor: pointer;
    }
  }
}




.cta-button {
  text-align: center;
  padding-bottom: 30px;

  &.classicButton {

    &.button-right {
      text-align: right;
    }

    &.button-left {
      text-align: left;
    }

    .cta-button-content {
      margin: 0 auto;
      overflow: auto;
      vertical-align: top;
      text-align: left;
      display: inline-block;
      width: 80%;

      .button,
      .icon {
        display: flex;
        position: relative;
        text-align: center;
        vertical-align: middle;
        padding: 10px 15px;
        border: 2px solid $ci-secondary;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        i {
          font-size: 25px;
          color: #fff;
          margin: auto;
          display: inline;
          position: relative;
        }
      }
      .icon {
        background: $ci-secondary;
        border-radius: $border-radius 0 0 $border-radius;
      }
      .button {
        background: transparent;
        margin-left: 0;
        display: inline-flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        width: 100%;
        border-radius: 0 $border-radius $border-radius 0;

        h3 {
          @extend .roboto-bold;
          text-transform: uppercase;
          color: $ci-primary;
          font-size: 21px;
          line-height: 26px;
          display: block;
          width: 100%;
        }
      }
      &:hover {
        .button,
        .icon {
          border-color: $ci-primary;
        }
        .icon {
          background-color: $ci-primary;
        }
      }
      .col-phone {
        color: #fff;
        position: relative;

        .dial-number {
          display: inline-block;
          width: 100%;
        }
        a, a:hover {
          display: inline-flex;
          text-decoration: none;
          color: #fff;
          width: 100%;
        }
      }

      .col-mail,
      .col-mail.quick-contact-trigger-box {
        color: #fff;
        position: relative;
        a, a:hover {
          display: inline-flex;
          text-decoration: none;
          color: #FFF;
          width: 100%;
        }
      }

      .col-link {
        color: #fff;
        position: relative;
        a, a:hover {
          display: inline-flex;
          text-decoration: none;
          color: #FFF;
          width: 100%;

          span {
            font-size: 14px;
            line-height: 16px;
            vertical-align: middle;
            display: inline-flex;
          }
        }
      }

      .col-mail.quick-contact-trigger-box {
        cursor: pointer;
      }
    }
  }
}



@include media-breakpoint-up(sm) {
  .cta-box {
    .cta-box-content {
      width: 70%;
    }
    &.classicButton {
      .cta-box-content {
        width: 70%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .cta-box {
    .cta-box-content {
      width: 80%;

      .col-phone, .col-mail {
        border: 0;
        float: left;
        width: 50%;
      }

      .col-phone {
        border-right: 2px solid $ci-primary;
        margin-right: -1px;
      }

      .col-mail {
        border-left: 0px solid transparent;
        margin-left: -1px;
      }
    }
    &.classicButton {
      .cta-box-content {
        width: 80%;
        .col-phone, .col-mail {
          border: 0;
          float: left;
          width: 50%;
        }
        .col-phone {
          border-right: 2px solid $ci-primary;
          margin-right: -1px;
        }
        .col-mail {
          border-left: 0px solid transparent;
          margin-left: -1px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cta-box {
    .cta-box-content {
      width: 80%;
    }
    &.classicButton {
      .cta-box-content {
        width: 80%;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .cta-box {
    .cta-box-content {
      width: 60%;
    }
  }
  .cta-button {
    &.classicButton {
      .cta-button-content {
        width: 90%;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .cta-button {
    &.classicButton {
      .cta-button-content {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cta-button {
    &.classicButton {
      &.button-right,
      &.button-left {
        text-align: center;
      }
      .cta-button-content {
        width: 80%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cta-button {
    &.classicButton {
      &.button-right,
      &.button-left {
        text-align: left;
      }
      .cta-button-content {
        width: 100%;
      }
      .icon {
        margin-left: 0 !important;
        &:before {
          display: none;
        }
        i {
          padding: 0 0 0 15px !important;
          font-size: 30px !important;
        }
      }
      .button {
        margin-right: 0 !important;
        &:after {
          display: none;
        }
        h3 {
          font-size: 19px !important;
          line-height: 25px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .cta-box {
    &.classicButton {
      .cta-box-content {
        width: 60%;
      }
    }
  }
  .cta-button {
    &.classicButton {
      .cta-button-content {
        width: 60%;
      }
    }
  }
}

.cta-external-container{
  text-align:center;
  margin-bottom:50px;
  background-color: #fff;
  img{
    width:100%;
  }
}

.cta-external {
  border:3px solid #F06400;
  padding:15px;
  display: inline-block;
  p {
    padding-bottom:14px;
  }
}

.quick-contact-nav {
  .cta-external-container {
    margin-bottom: 0;
    /*width: 195px; corona */
    width: 216px;
    text-align: right;
    position: absolute;
    right: 0;
    /*bottom: -75px; corona*/
    /*bottom: -205px;*/
    margin-top:2px;
    font-size: 16px;
    padding: 0;
  }
  .cta-external {
      padding:5px 3px 3px 3px;
      width:100%;
      p {
        line-height: 19px;
        padding-bottom:7px;
      }
  }
}

@include media-breakpoint-down(sm) {
  .quick-contact-nav {
    .cta-external-container {
      margin-bottom: 0;
      width: 100%;
      text-align: center;
      position: fixed;
      bottom: 0px;
      font-size: 16px;
      right: 0;
      padding: 0;
      margin: auto auto;
      border-top:6px solid $ci-secondary;
      border-right:0;
      border-bottom:0;
      border-left:0;

    }
    .cta-external {
      padding:5px 3px 3px 3px;
      border:none;
      width:80%;
      p {
        line-height: 19px;
        padding-bottom:2px;
      }
    }
  }
}