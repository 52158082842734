#header-search {

  a {
    display: block;
    position: relative;
    padding: 30px 20px;
    text-align: center;
    color: $ci-primary;
    @include transition-effekt;
    z-index: 11;
    i {
      @include transition-effekt;
    }

  }

  form {
    opacity: 0;
    z-index: -2;
    position: absolute;
    width: 300px;
    height: 100% !important;
    padding: 0 15px;
    top: 0;
    left: -275px;
    background: #fff;
    @include transition-effekt;

    input {
      box-shadow: none !important;
      pointer-events: none;
      border-radius: 0;
      border: 0;
      outline: 0 !important;
      padding: 10px 80px 10px 0;
      height: 50px;
      width: 100%;
      background-color: transparent;
      color: $ci-primary;
      margin-top: 0 !important;
      position: relative;
      @include placeholder($ci-primary);
    }

  }
}

body.header-search-open a.header-search-trigger i.fa-magnifying-glass,
a.header-search-trigger i.fa-xmark {
  opacity: 0;
  width: 0;
  @include transition-effekt;
}

a.header-search-trigger i.fa-magnifying-glass,
body.header-search-open a.header-search-trigger i.fa-xmark {
  opacity: 1;
  z-index: 11;
  width: 15px;
  @include transition-effekt;
}


body.header-search-open #header-search {
  form {
    opacity: 1;
    z-index: 10;
    @include transition-effekt;
    input { pointer-events: auto; }

  }
}

body.header-search-open .menu-trigger {
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
}

@include media-breakpoint-down(md) {
  #header-search {
    height: 0px;
    @include transition-one-effekt(height);

    a {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      padding: 30px 20px;
      width: 65px;
      height: 75px;
      text-align: center;
      color: $ci-primary;
      -webkit-transition: color .3s ease-in-out;
      -o-transition: color .3s ease-in-out;
      transition: color .3s ease-in-out;
      z-index: 11;
      i {
        -webkit-transition: opacity .3s ease;
        -o-transition: opacity .3s ease;
        transition: opacity .3s ease;
      }
    }

    form {
      position: relative;
      padding: 0;
      margin: 0;

      input {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px 0px 10px 0;
      }
    }
  }
  body.header-search-open #header-search {
    height: 50px;
    @include transition-one-effekt(height);

  }
}
@include media-breakpoint-up(lg) {
  #header-search {
    display: inline-block;
    width: 30px;
    height: auto;
    vertical-align: top;

    a {
      height: 63px;
      width: 30px;
      padding: 19px 0 23px;
      text-align: right;
    }

    form {
      opacity: 0;
      z-index: -2;
      position: absolute;
      top: auto;
      bottom: 0;
      height: 63px !important;
    }
  }

  .shrinked-header {
    #header-search {

    }
  }
  .header-search-open {
    #header-search {
      form {

      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #header-search {
    width: 40px;
    position: absolute;
    right: 0;
    a {
      width: 40px;
    }
  }
}

#additional_filters {
  display: none;
  margin-bottom: 30px;
  text-align: center;
}

#toggle-additional-filters {
  color: $ci-primary;
  margin-bottom: 20px;
  float: right;
}

.sorting label {
  cursor: pointer;
}

.tx-kesearch-pi1 {
  .result-list-item{
    clear:both;
    margin: 20px 0 30px 0;
    padding: 0;
  }
  .result-list-item .hit {
    color: red;
    font-weight: bold;
  }

  .submitbutt {
    display: block;
    margin-top: 20px;
    input {
      @extend .btn;
      @extend .btn-blue;
    }
  }
}