@charset "UTF-8";

.tx-felogin-pi1,
.tx-powermail,
.tx-kesearch-pi1 {
  fieldset {
    //@include make-row(30px);
  }
  label {
    color: #555;
    line-height: 30px;
    padding: 20px 0 0;
    display: block;
    font-weight: normal;
    &.label-cb-radio {
      margin:10px 0 0 0;
    }
  }
  .powermail_fieldset .powermail_field_error, 
  .powermail_fieldset div.error {
    background-color: transparent !important;
    label, a {
      color: #A94442 !important;
      font-weight: bold;
    }
  }
  .powermail_fieldwrap_anrede .form-element-field {
    display: inline-flex;
    .radio {
      width: 80px;
      .powermail_field_error_container {
        display: none;
      }
    }
  }
  .radio label, .checkbox-inline label{
    padding-top:0px;
    margin-right:20px;
  }
  .checkbox-inline{
    margin-left:0px!important;
  }
  input[type=email],
  input[type=text],
  input[type=password],
  input[type=search],
  textarea {
    background: #EBEBEB !important;
    height: 60px;
    border: 2px solid #EBEBEB;
    border-radius: $border-radius;
    font-size: 24px;
    line-height: 30px;
    padding: 12px;
    width: 100%;
    font-family: $font-family-serif;

    // wenn aktive state unbedingt auch bei ausgefüllt erwünscht, gibt es mehrere Möglichkeiten
    // z.B. alle Felder als required (required="required") kennzeichnen und :valid
    // oder javascript Lösung:
    //https://stackoverflow.com/questions/16952526/detect-if-an-input-has-text-in-it-using-css-on-a-page-i-am-visiting-and-do-no

    &:active, &:focus {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }
    &.parsley-error {
      background: #fff;
      border-color: #D9D9D9;
      outline: 0;
    }
  }

  .checkbox.parsley-error {
    background: transparent;
    color: #f00000;
  }
  .parsley-error,
  .powermail_error {
    label, label a {
      color: #f00000;
    }
    input[type=text], textarea {
      background: #fff;
      border-color: #f00000;
      color: #f00000;
      outline: 0;
    }
  }

  .powermail_fieldwrap_type_check,
  .powermail_fieldwrap_type_radio {
    .form-element-label {
      display: none;
    }
  }
  .powermail_fieldwrap_type_radio {
    .form-element-field {
      .radio {
        label {
          line-height: 1.42857;
        }
        .powermail-errors-list {
          width: auto;
          float: left;
          li {
            width: max-content;
          }
        }
      }
    }
  }
  .powermail_fieldwrap_type_check {
    .form-element-field {
      label {
        line-height: 1.42857;
      }
    }
  }
}

.newsletter {
  .checkbox-inline{
    width:270px;
  }
  .radio{
    width:180px;
  }
}
.powermail_form_forget,
.powermail_form_login {
  h2 {
    padding: 0 15px 10px;
  }

  .powermail_fieldwrap_forgot-email,
  .powermail_fieldwrap_user,
  .powermail_fieldwrap_password {
    @include make-col-ready(30px);
    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }
  .powermail_fieldwrap_password {
    clear: left;
  }
  .powermail_submit {
    @extend .btn-large-blue;
    width: auto !important;
  }
  .powermail_fieldwrap_type_submit {
    clear: both;
    width: 100%;
    padding: 40px 15px 20px 15px;
  }

}

.tx-felogin-pi1,
.powermail_form_2 {
  h2 {
    padding: 0 0 50px;
  }

  .powermail_fieldwrap_user,
  .powermail_fieldwrap_password,
  .powermail_fieldwrap_newpassword1,
  .powermail_fieldwrap_newpassword2,
  .powermail_fieldwrap_anrede,
  .powermail_fieldwrap_plz,
  .powermail_fieldwrap_firstname,
  .powermail_fieldwrap_lastname,
  .powermail_fieldwrap_streetaddress,
  .powermail_fieldwrap_zipcode,
  .powermail_fieldwrap_city,
  .powermail_fieldwrap_phone,
  .powermail_fieldwrap_email,
  .powermail_fieldwrap_type_captcha {
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-up(md) {
      float: left;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      float: left;
      width: 33.33333%;
    }
  }
  .powermail_fieldwrap_streetaddress,
  .powermail_fieldwrap_phone,
  .powermail_fieldwrap_zipcode
   {
    clear: left;
  }

  .powermail_fieldwrap_message {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  .powermail_fieldwrap_type_submit,
  .powermail_fieldwrap_absenden {
    clear: both;
    width: 100%;
    padding: 70px 15px 0 15px;
  }
  .powermail_submit {
    @extend .btn-large-blue;
  }
  .powermail_fieldwrap_datenschutzhinweis,
  .powermail_fieldwrap_zustimmungagb,
  .powermail_fieldwrap_widerrufserklaerung,
  .powermail_fieldwrap_type_captcha {
    clear: both;
    padding: 0 15px;

    label {
      color: $text-color;
    }
    .powermail_field_error_container {
      display: none;
    }
  }
}

.tx-felogin-pi1 {
  .powermail_fieldwrap_type_submit {
    padding: 40px 15px 40px 15px;
  }
}

.powermail_fieldwrap_anrede {
  width: 100% !important;
  float: none !important;
}


@include media-breakpoint-down(md) {
  .tx-powermail {
    .powermail_fieldwrap_message {
      clear: both;
    }
  }
}
@include media-breakpoint-up(lg) {
  .tx-powermail {
    .powermail_fieldwrap_message {
      margin-left: 66.6666%;
      textarea {
        height: 280px;
      }
    }
    .powermail_fieldwrap_datenschutzhinweis,
    .powermail_fieldwrap_zustimmungagb,
    .powermail_fieldwrap_widerrufserklaerung {
      width: 66.6666%;
    }
  }
}

.alert-error {
  margin: 5px 0 20px 0;
  list-style-type: none;
  background-color: #F2DEDE;
  border: 1px solid #EBCCD1;
  color: #A94442;
  padding: 5px 10px;
  border-radius: $border-radius;
}

fieldset.fe_login {
  .felogin_error {
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.femanager_gender {
  .controls {
    position: relative;

    .radio {
      position: static !important;
      .alert-error {
        position: absolute;
        left: 150px;
        top: -5px;
        margin-right: 15px;
      }
    }
  }
}
#renditerechner {
  .form-control {
    display:block;
    width:100%;
    height:34px;
    padding:6px 12px;
    font-size:14px;
    line-height:1.42857;
    color:#555;
    background-color:#fff;
    background-image:none;
    border:1px solid #ccc;
    border-radius:4px;
    -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition:border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    -o-transition:border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    transition:border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s
  }
  .form-control:focus {
    border-color:#66afe9;
    outline:0;
    -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);
    box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6)
  }
  .form-control::-moz-placeholder {
    color:#999;
    opacity:1
  }
  .form-control:-ms-input-placeholder {
    color:#999
  }
  .form-control::-webkit-input-placeholder {
    color:#999
  }
  .form-control::-ms-expand {
    border:0;
    background-color:transparent
  }
}