body, html {
  position: relative;
  height: 100%;
}

.wrap {
  position: relative;
  height: 100%;
}

.section-content {
  display: flex;
  flex-direction: column;
}

.section-dark, .section-dark-container-fluid, .section-dark-container {

}

.section-dark-container-fluid, .section-dark-container {
  .section-content {
    @include make-container();
  }
}

.section-dark-container {
  .section-content {
    @each $breakpoint, $container-max-width in $container-max-widths {

      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        width: $container-max-width;
      }
    }
  }
}

.light-primary-brand-box-secondary-brand-border,
.light-primary-brand-color-box, 
.light-secondary-brand-color-box, 
.panel-accordion{
  background: $ci-tertiary;
  border-radius: $border-radius;
  padding:30px 30px;
  margin-top:0;
  margin-bottom:40px;
  ul, ol{
    margin:0 0 20px 0;
    padding:0;
  }
  li{
    margin:10px 0 10px 19px;
    line-height:22px;
    padding:0 1px 0 2px;
  }
  ul li{
    list-style-type: square;
    list-style-position: outside;
    padding: 0;

    &::marker {
      color: $list-icon-color;
    }
  }
  p:last-of-type {
    padding-bottom: 0px;
  }
}

.light-primary-brand-box-secondary-brand-border {
  ul li {

  }
}


.light-secondary-brand-color-box{
  background: $ci-tertiary;
  ul li{
    border-left: 2px solid $ci-primary;
  }
}

.primary-brand-color-border{
  border:2px solid $brand-primary;
  padding:10px;
  p{
    padding-bottom:0;
  }
}
.secondary-brand-color-border{
  border:2px solid $brand-secondary;
  padding:10px;
  p{
    padding-bottom:0;
  }
}

.white-brand-color-box {
  background: $white;
  border-radius: $border-radius;
  padding:30px;
}

.equal-height{
  .primary-brand-color-border, .secondary-brand-color-border{
    height:100%;
  }
}

.content-center {
  text-align: center;

  img.responsive-image, .img-fluid {
    margin: 0 auto;
  }

}

.default-padding {
  padding-bottom: 80px;
}

.padding-top-bottom {
  padding-top: 80px;
  padding-bottom: 80px;
}


.responsive-image {
  @include img-fluid();
}
.ce-gallery img {
  @extend .img-fluid;
}
#hero-content {
  padding-top: 77px;
  .container {
    padding: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
}

#content {
  padding: 70px 0 50px;
  display: flex;
  flex-direction: column;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  height: 1px;
  background: $border-color;
}

p + ul {
    margin-top: -30px;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.header-image-block {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .teaser-box-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    display:none;

    .teaser-wrap {
      @extend .container;
    }

    .teaser {
      border-radius: 7px;
      padding: 20px 20px;
      background: transparentize($white,0.1);
      color: #fff;
      float: left;
      .headline, h1 {
        @extend .font-teaser;
        padding-bottom: 10px;
        color: $header-teaser-box-headline-color;

      }

      .subline, h4, p {
        @extend .roboto-regular;
        padding-bottom: 0px;
        font-size: 17px;
        color: $header-teaser-box-subline-color;
      }
    }
  }
}

.container .header-image-block,
.header-image-block.container {
  .teaser-box-wrap {
    .teaser-wrap {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    .teaser {
      margin-left: 30px;
    }
  }
}

h1, h2, h3, h4,
div,
span,
p {
  &.lightgrey {
    color: #ddd;
  }
  &.white {
    color: #fff;
  }
  &.darkgrey {
    color: #333;
  }
  &.blue {
    color: $brand-primary;
  }
  &.orange {
    color: $brand-secondary;
  }
}


.mb-mg0 {
  margin-bottom: 0 !important;
}
.mb-mg10 {
  margin-bottom: 10px !important;
}
.mb-mg15 {
  margin-bottom: 15px !important;
}
.mb-mg20 {
  margin-bottom: 20px !important;
}
.mb-mg25 {
  margin-bottom: 25px !important;
}
.mb-mg30 {
  margin-bottom: 30px !important;
}
.mb-mg40 {
  margin-bottom: 40px !important;
}
.mb-mg50 {
  margin-bottom: 50px !important;
}

.mt-mg0 {
  margin-top: 0 !important;
}
.mt-mg10 {
  margin-top: 10px !important;
}
.mt-mg15 {
  margin-top: 15px !important;
}
.mt-mg20 {
  margin-top: 20px !important;
}
.mt-mg25 {
  margin-top: 25px !important;
}
.mt-mg30 {
  margin-top: 30px !important;
}
.mt-mg40 {
  margin-top: 40px !important;
}
.mt-mg50 {
  margin-top: 50px !important;
}

@include media-breakpoint-down(md) {

  .header-image-block {
    .teaser-box-wrap {
      bottom: 15px;
    }
    .teaser-wrap {
      .teaser {
        max-width: 460px;
        margin-left: 15px;
        margin-right: 15px;
        .headline, h1 {
          font-size: 30px;
          line-height: 35px;
          padding-bottom: 10px;
          	
        }

        .subline, h4 {
          font-family: 'Roboto Condensed';
          font-weight: $font-weight-bold;
          padding-bottom: 0px;
        }
      }
    }
  }
  .container .header-image-block,
  .header-image-block.container {
    .teaser-wrap {
      .teaser {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .header-image-block {
    .teaser-box-wrap {
      display:block;
    }
  }
}

@include media-breakpoint-up(lg) {
  #hero-content {
    padding-top: 110px;
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .header-image-block {
    .teaser-wrap {
      .teaser {
        max-width: 560px;
      }
    }
  }
  .section-header {
    text-align: center;
    padding-bottom: 120px;
  }
}


@include media-breakpoint-up(xl) {
  .container {
    //width: 970px;
  }
}

header {
    h1, h2 {
        padding-bottom: 0;
    }
}

.type-sparda_videostart, 
.type-sparda_slider {
  display: contents;
  height: 100%;
}